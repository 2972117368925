import { Routes } from '@angular/router';
import { EventsMainComponent } from './events-main/events-main.component';
import { LoginComponent } from './login/login.component';
import { authGuardGuard } from './shared/auth-guard.guard';
import { UserMainComponent } from './user-main/user-main.component';
import { EventsManageComponent } from './events-manage/events-manage.component';
import { AppMainLayoutComponent } from './app-main-layout/app-main-layout.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { RegisterComponent } from './register/register.component';
import { UserParticipantsComponent } from './user-participants/user-participants.component';
import { UserParticipantCreateComponent } from './user-participant-create/user-participant-create.component';
import { UserParticipantEditComponent } from './user-participant-edit/user-participant-edit.component';
import { EventParticipantManageComponent } from './event-participant-manage/event-participant-manage.component';
import { EventParticipantQuestionsComponent } from './event-participant-questions/event-participant-questions.component';
import { EventParticipantPaymentComponent } from './event-participant-payment/event-participant-payment.component';
import { EventParticipantPaymentManageComponent } from './event-participant-payment-manage/event-participant-payment-manage.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AttendanceSheetComponent } from './attendance-sheet/attendance-sheet.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { EventParticipantPaymentInformationComponent } from './event-participant-payment-information/event-participant-payment-information.component';

// Import the MainComponent class
export const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: '',
    redirectTo: '/events',
    pathMatch: 'full'
  },
  {
    path: 'main',
    canMatch: [authGuardGuard],
    component: AppMainLayoutComponent
  },
  {
    path: 'users',
    canMatch: [authGuardGuard],
    component: UserMainComponent
  },
  {
    path: 'users-manage/:id',
    canMatch: [authGuardGuard],
    component: UserManageComponent
  },
  {
    path: 'users-participants/:id',
    canMatch: [authGuardGuard],
    component: UserParticipantsComponent
  },
  {
    path: 'users-participant-create/:id',
    canMatch: [authGuardGuard],
    component: UserParticipantCreateComponent
  },
  {
    path: 'users-participant-edit/:id/:participantID/:canEdit',
    canMatch: [authGuardGuard],
    component: UserParticipantEditComponent
  },
  {
    path: 'users-participant-view/:id/:participantID/:canEdit',
    canMatch: [authGuardGuard],
    component: UserParticipantEditComponent
  },
  {
    path: 'events',
    canMatch: [authGuardGuard],
    component: EventsMainComponent
  },
  {
    path: 'events-manage/:id/:state',
    canMatch: [authGuardGuard],
    component: EventsManageComponent
  },
  {
    path: 'events-participants/:id',
    canMatch: [authGuardGuard],
    component: EventParticipantsComponent
  },
  {
    path: 'events-create/:state',
    canMatch: [authGuardGuard],
    component: EventsManageComponent
  },
  {
    path: 'events-participant-manage/:state/:event_participant_ID/:event_ID',
    canMatch: [authGuardGuard],
    component: EventParticipantManageComponent
  },
  {
    path: 'events-participant-questions/:state/:event_participant_ID/:event_ID',
    canMatch: [authGuardGuard],
    component: EventParticipantQuestionsComponent
  },
  {
    path: 'events-participant-payment/:event_participant_ID/:event_ID/:checkout_secret',
    canMatch: [authGuardGuard],
    component: EventParticipantPaymentComponent
  },
  {
    path: 'events-participant-payment-manage/:event_participant_ID/:event_ID/:user_ID',
    canMatch: [authGuardGuard],
    component: EventParticipantPaymentManageComponent
  },
  {
    path: 'forgot-password/:email',
    component: ForgotPasswordComponent
  },
  {
    path: 'attendance/:event_ID',
    component: AttendanceSheetComponent
  },
  {
    path: 'events-participant-payment-info/:event_participant_ID/:event_ID',
    component: EventParticipantPaymentInformationComponent
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  }
];
