<div class="title"> Events Management </div>

<div class="container">
  <div class="horizontal-container">
    <div class="horizontal-item">
      <div class="horizontal-item entry_title" style="width:100%;">
        Event Details
      </div>

      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item entry_subtitle" style="width: 100%">
            Event Title <br>
            <input class="select" type="search" maxlength="50" placeholder="Event Name" [(ngModel)]="eventName">
        </div>
      </div>

      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item entry_subtitle" style="width: 100%">
            Facilitator Name <br>
            <input class="select" type="search" placeholder="Facilitator" [(ngModel)]="facilitator">
        </div>
      </div>

      <br>

      <div class="horizontal-container">
        <div class="horizontal-item entry_title" style="width: 100%">
          Participant Criteria <br>
        </div>
      </div>

      <div class="horizontal-container" class="container" style="margin-left:10px">

        <div *ngIf="!allAges" class="horizontal-item" style="width: 30%">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Min Age
            <br>
            <input #minAgeInput class="select" style="margin-top:5px; padding-top:5px; padding-bottom:5px; width:50px;" type="number" placeholder="Age" [(ngModel)]="minAge" (blur)="blurMinAge()">
          </div>
        </div>
        <div *ngIf="!allAges" class="horizontal-item" style="width: 30%" >
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Max Age
            <br>
            <input #minAgeInput class="select"style="margin-top:5px; padding-top:5px; padding-bottom:5px; width:50px;" type="number" placeholder="Age" [(ngModel)]="maxAge" (blur)="blurMaxAge()">
          </div>
        </div>
        <div class="horizontal-item" style="width: 30%">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            All Ages
            <br>
            <input type="checkbox" style="margin-top:12px; margin-left:10px; transform: scale(1.6);" [(ngModel)]="allAges"/>
          </div>
        </div>
      </div>
      <div class="horizontal-container" class="container">
        <div class="horizontal-item" style="width: 58%"  style="margin-left:10px;" *ngIf="currentParticipants">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Current
            <br>
            <div class="txt" style="margin-top: 11px;">{{currentParticipants}}</div>
          </div>
        </div>
        <div class="horizontal-item" style="width: 40%"  style="margin-left:10px">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Max Participants
            <br>
            <input class="select"style="margin-top:5px; padding-top:5px; padding-bottom:5px; width:80px;" type="number" placeholder="Price" [(ngModel)]="maxParticipants" (blur)="blurEventParticipants()">
          </div>
        </div>
      </div>
    </div>


    <div class="horizontal-item" style="padding-left:20px">
      <div class="horizontal-item entry_title" style="width:100%;">
        Location
      </div>
      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item entry_subtitle" style="width:100%;">
          Region
          <select [(ngModel)]="region" class="select"  #regionInput (change)="OnRegionSelect()">
            <option *ngFor="let item of regions" [value]="item.id">{{ item.region_name }}</option>
          </select>
        </div>
      </div>
      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item entry_subtitle" style="width:100%;">
          Location
          <select [(ngModel)]="location" class="select" >
            <option *ngFor="let item of locations" [value]="item.id">{{ item.location_name }}</option>
          </select>
        </div>
      </div>

      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item" style="width: 100%">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Address
            <br>
            <input class="select" type="search" placeholder="Address" [(ngModel)]="address">
          </div>
        </div>
      </div>

      <div class="horizontal-container" style="margin-left:10px">
        <div class="horizontal-item" style="width: 100%">
          <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
            Virtual Event
            <br>
            <input type="checkbox" style="margin-top:12px; margin-left:10px; transform: scale(1.6);" [(ngModel)]="virtualEvent"/>
          </div>
        </div>
      </div>

      <!-- <div class="horizontal-container">
        <div class="horizontal-item">
          <div class="selector-box entry_title" style="width: 100%">
            <div class="checkbox-wrapper-19" style="width:100%; margin-top:-1px; align-content: center;" >
              <div class="checkbox-wrapper-47" style="width:100%" >
                <input type="checkbox" name="cb_virtual" id="cb-49" [(ngModel)]="virtualEvent"/>
                <label for="cb-49" class="txt" style="width:100%" >Virtual Event</label>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <br>

  <div class="horizontal-container">
    <div class="horizontal-item">
      <div class="entry_title">
        Event Image
      </div>
      <div class="txt" style="font-size:small">
        Only PNG and JPG files are supported.
      </div>
      <div class="container">
        <div class="horizontal-container">
          <div class="horizontal-item" style="width:70%; text-align: center;">
            <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" class="uploadButton" style="width:100%"/>
          </div>
          <div *ngIf="this.imagePresent" class="horizontal-item" style="text-align: center;" style="width:26%; text-align: center;">
            <button class="icon_button" (click)="DeleteImage()"> <img class="svg" src="assets/svg/delete.svg" alt="view"> </button>
          </div>
        </div>
      </div>
      <br>
      <div *ngIf="imagePresent" class="container">
        <img class="images" style="height: 180px" [src]="current_url" alt="current"/>
      </div>
    </div>
    <div class="horizontal-item" style="width:100%" style="padding-left:20px">
      <div class="selector-box entry_title" style="width: 100%">
        Event Payment Options
      </div>
      <div class="checkbox-wrapper-19" style="width:100%; margin-top:-1px; align-content: center;" >
        <div class="checkbox-wrapper-47" style="width:100%" >
          <input type="checkbox" name="cb" id="cb-47" [(ngModel)]="isPaid"/>
          <label for="cb-47" class="txt" style="width:100%" >Paid Event</label>
        </div>
      </div>
      <div *ngIf="isPaid" class="container" style="padding-left:10px">
        <div class="horizontal-container">
          <div class="horizontal-item" style="width: 45%">
            <div class="entry_subtitle" style="width: 100%; padding-top: 20px">
              Price
              <br>
              <input class="select" style="margin-top:2px; padding-top:5px; padding-bottom:5px; width:90px;" type="number" placeholder="Price" [(ngModel)]="eventPrice" (blur)="blurEventPrice()">
            </div>
          </div>
        </div>
          <div class="horizontal-container">
            <div *ngIf="ShowScholarshipsAwarded()" class="horizontal-item" style="width: 55%">
              <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
                Scholarships Awarded
                <br>
                <div class="txt" style="margin-top:2px; padding-top:5px; padding-bottom:5px; width:80px;">{{scholarshipsAwarded}}</div>
              </div>
            </div>
            <div class="horizontal-item" style="width: 45%">
              <div class="entry_subtitle" style="width: 100%; padding-top: 5px">
                Max Scholarships
                <br>
                <input class="select" style="margin-top:2px; padding-top:5px; padding-bottom:5px; width:80px;" type="number" placeholder="Price" [(ngModel)]="maxScholarships" (blur)="blurEventScholarships()">
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <br>
  <div class="container">
    <div class="entry_title">
      Details
      <textarea class="select" type="text" placeholder="Details" rows="7" maxlength="5000"  [(ngModel)]="description"></textarea><br>
    </div>
  </div>

  <br>

  <div class="entry_title">
    Event Schedule
  </div>
  <br>
  <div class="horizontal-container">
    <div class="horizontal-item">
      <div class="entry_subtitle"> Start </div>
    </div>
    <div class="horizontal-item">
      <div class="entry_subtitle"> End </div>
    </div>
  </div>

  <div *ngFor="let item of schedules; index as i" style="width:100%">
    <div class="horizontal-container">
      <div class="horizontal-item" style="width:5%">
        <div class="txt"> {{i+1}} </div>
      </div>
      <div class="horizontal-item">
        <input class="select" [min]="currentDateTime" type="datetime-local" placeholder="Start"  #myInput (blur)="OnScheduleBlur(item, i)" [(ngModel)]="item.start">
      </div>
      <div class="horizontal-item">
        <input class="select" [disabled]="!this.schedules[i].start ? true : false" [min]="this.schedules[i].start ? item.start : null" type="datetime-local" placeholder="Finish"  #myInput (blur)="OnScheduleBlur(item, i)" [(ngModel)]="item.end" >
      </div>
      <div *ngIf="displayRemoveButton(i)" class="horizontal-item" style="width:25%">
        <button class="button-delete-mid" (click)="removeDate(i)"> REMOVE </button> <br>
      </div>
      <div *ngIf="!displayRemoveButton(i)" class="horizontal-item" style="width:25%">

      </div>
    </div>
  </div>

  <br>
  <br>
  <div class="horizontal-container">
    <div class="horizontal-item" style="width:55%">
      <div class="entry_title"> Questions </div>
    </div>
    <div *ngIf="areThereQuestions()" class="horizontal-item" style="width:20%">
      <div class="entry_title"> Answer Type </div>
    </div>
    <div class="horizontal-item" style="width:25%">
    </div>
  </div>


  <div *ngFor="let item of questions; index as i" style="width:100%">
    <div class="horizontal-container">
      <div class="horizontal-item" style="width:55%">
        <textarea maxlength="500"  class="select" type="text" placeholder="Question" rows="3" #myInput (blur)="OnQuestionBlur(item, i)" [(ngModel)]="item.question"></textarea>
      </div>
      <div class="horizontal-item" style="width:20%">
        <select [(ngModel)]="item.answer_type" class="select">
          <option  *ngFor="let item of answerTypes" [value]="item.value">{{ item.label }}</option>
        </select>
      </div>
      <div class="horizontal-item" style="width:25%">
        <button class="button-delete-mid" (click)="removeQuestion(i)"> REMOVE </button> <br>
      </div>
    </div>
  </div>

  <div class="txt" *ngIf="!areThereQuestions()">
    This section allows you to add questions that are unique to the event. You can add as many questions as you want.
    In order to add new questions, you must finish filling out the current question and question type.
  </div>

  <div >
    <button class="button-mid" style="width:200px" [hidden]="!canAddNewQuestion()" [disabled]="!canAddNewQuestion()" (click)="addQuestion()"> NEW QUESTION </button> <br>
    <div class="txt"  [hidden]="canAddNewQuestion()"> Can add new question once previous question and answer type filled in. </div> <br>
  </div>

<br>
<br>
<br>
<br>

<div class="entry_title">
  <div class="horizontal-container" *ngIf="show_master_buttons">
    <div *ngIf="state === 'edit'" style="width:100%">
      <div class="horizontal-item" style="width:31%">
        <button class="button" (click)="SaveEdit()"> SAVE DRAFT </button> <br>
      </div>
      <div class="horizontal-item" style="width:31%">
        <button class="button" (click)="PromptPublishEvent()"> PUBLISH EVENT </button> <br>
      </div>
      <div class="horizontal-item" style="width:31%">
        <button class="button-delete" (click)="DeleteEvent()"> DELETE EVENT </button> <br>
      </div>
    </div>
    <div *ngIf="state === 'published'" style="width:100%">
      <div class="horizontal-item" style="width:31%">
        <button class="button" (click)="EditPublishedEvent()"> EDIT EVENT </button> <br>
      </div>
      <div class="horizontal-item" style="width:31%">

      </div>
      <div class="horizontal-item" style="width:31%">
        <button class="button-delete" (click)="DeleteEvent()"> DELETE EVENT </button> <br>
      </div>
    </div>
    <div *ngIf="state === 'create'" style="width:100%">
      <button class="button" (click)="CreateEvent()"> Create EVENT </button> <br>
    </div>
  </div>
</div>

